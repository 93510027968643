import {
  Card,
  Checkbox,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Typography,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import {
  Contractor,
  FileObjectType,
  FileTypeEnum,
  IncomeTypeEnum,
  LaborContract,
  LaborContractStatusEnum,
  PaymentMethodEnum,
  PublicLaborContractUpdateDto,
  TaxClassificationEnum,
  useSetPublicLaborContractSignatureMutation,
  useSubmitPublicLaborContractMutation,
  useUpdateLaborContractMutation,
  useUpdatePublicLaborContractMutation,
} from "../generated/graphql";
import { getAccessToken } from "../auth/Auth";
import UploadFile from "../common/UploadFile";
import {
  AlertDialogButton,
  EditTextField,
  PublicMessageEnum,
  StyleTextField,
} from "./LaborContractDetail";
import NotificationPopup from "../common/NotificationPopup";
import ConfirmButton from "../component/ConfirmButton";
import { map, omit, pick } from "lodash";
import {
  IncomeTypeMap,
  NoInsuranceMap,
  PaymentMethodMap,
  TaxClassificationMap,
} from "../common/Constant";
import { newCalculateContractTotals } from "../common/Utils";
import Signature from "../common/Signature";
import React, { useState } from "react";
import { AlertCircle } from "react-feather";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getPublishEnum } from "./LaborContractDetail";
import theme from "../theme/Theme";
import NumberFormat from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";

export const EditSelect = (props) => {
  return (
    <FormControl {...props} style={{ ...props.style, width: "100%" }}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        {...props}
        value={props.value === 0 ? 0 : props.value || ""}
        onChange={({ target }) => {
          if (props.setter) {
            props.setter(target.value);
          }
        }}
      >
        {props.children}
      </Select>
    </FormControl>
  );
};

const PreviewImage = ({ file, isSignature = false }) => {
  return file ? (
    <Grid container alignItems={"center"} direction="column">
      <Grid item style={{ marginBottom: 10 }}>
        {isSignature ? "簽名" : "上傳"}
        日期：{format(new Date(file.createdAt), "yyyy-MM-dd")}
      </Grid>
      <Grid item>
        <img
          style={{ maxWidth: "250px", marginBottom: 10 }}
          src={file.uri + `?token=${getAccessToken()}`}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: {
    target: { name: string; value: string | number | undefined };
  }) => void;
  name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

export const styleBorderBR = {
  borderBottom: "1px solid",
  borderRight: "1px solid",
  borderColor: theme.palette.primary.main,
};
export const titleBarStyle = {
  backgroundColor: theme.palette.primary.dark,
  color: "#000000",
  borderColor: theme.palette.primary.main,
};

const DescriptionButton = ({
  title = undefined,
  children,
}: {
  title?: string | undefined;
  children: any;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Dialog open={open} fullWidth={true}>
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              setOpen(false);
            }}
          >
            確認
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        onClick={() => {
          setOpen(true);
        }}
      >
        <AlertCircle />
      </IconButton>
    </div>
  );
};

const incomeDescriptionRows = [
  {
    id: 1,
    col1: "9B稿費",
    col2: "包含稿費、演講費、版稅、編劇、作曲、樂譜、漫畫之收入",
  },
  {
    id: 2,
    col1: "9A執行業務所得",
    col2: "包含律師、會計師、建築師、技師、醫師、藥師、助產士、著作人、",
  },
  {
    id: 3,
    col1: "",
    col2: "經紀人、代書、工匠、表演人及其他以技藝自力營生者之業務收入。",
  },
  { id: 4, col1: "50薪資所得", col2: "兼職人員所得" },
  { id: 5, col1: "92其他所得", col2: "其他所得" },
];
const insuranceDescription = (
  taxClassification: TaxClassificationEnum,
  incomeType: IncomeTypeEnum
) => {
  if (taxClassification == TaxClassificationEnum.NonResidentAlien) {
    return "不需扣2.11%二代健保補充保費";
  }
  switch (incomeType) {
    case IncomeTypeEnum.A_9a:
    case IncomeTypeEnum.A_9b:
      return "支領金額低於兩萬，不需扣2.11%二代健保補充保費\n；高於或等於兩萬，需扣2.11%二代健保補充保費";
    case IncomeTypeEnum.A_50:
      return "支領金額低於27,470 ，不需扣2.11%二代健保補充保費\n；高於或等於27,470 ，需扣2.11%二代健保補充保費";
    case IncomeTypeEnum.A_92:
      return "不需扣2.11%二代健保補充保費";
  }
  return "若所得人之國籍或勞務內容之所得類別未填，則無法計算代扣所得稅及二代健保金額。";
};
const taxDescription = (
  taxClassification: TaxClassificationEnum,
  incomeType: IncomeTypeEnum
) => {
  if (
    taxClassification == TaxClassificationEnum.Citizen ||
    taxClassification == TaxClassificationEnum.ResidentAlien
  ) {
    switch (incomeType) {
      case IncomeTypeEnum.A_9b:
        return "支領金額低於或等於兩萬，不需扣繳所得稅\n；高於兩萬，需扣繳10%所得稅";
      case IncomeTypeEnum.A_9a:
        return "支領金額低於或等於兩萬，不需扣繳所得稅\n；高於兩萬 ，需扣繳10%所得稅";
      case IncomeTypeEnum.A_50:
        return "支領金額低於88,501不需扣繳所得稅\n；高於或等於88,501，需扣繳5%所得稅";
      case IncomeTypeEnum.A_92:
        return "不需扣繳所得稅";
    }
  } else if (taxClassification == TaxClassificationEnum.NonResidentAlien) {
    switch (incomeType) {
      case IncomeTypeEnum.A_9b:
        return "支領金額低於或等於五千，不需扣繳所得稅\n；高於五千 ，需扣繳20%所得稅";
      case IncomeTypeEnum.A_9a:
        return "支領金額需扣繳20%所得稅";
      case IncomeTypeEnum.A_50:
        return "支領金額低於或等於41,206 ，需扣繳6%所得稅\n；高於41,206，需扣繳18%所得稅";
      case IncomeTypeEnum.A_92:
        return "需扣繳20%所得稅";
    }
  } else {
    return "若所得人之國籍或勞務內容之所得類別未填，則無法計算代扣所得稅及二代健保金額。";
  }
};
const checkPublish = (laborContract) => {
  let canPublish = true;
  let keys = [
    "contractorName",
    "contractorAddress",
    "contractorEmail",
    "contractorPhone",
    "contractorIdNumber",
    "contractorTaxClassification",
    "contractorContactAddress",
    "signatureFile",
    "idFrontFile",
  ];
  if (
    laborContract.contractorTaxClassification == TaxClassificationEnum.Citizen
  ) {
    keys = [...keys, "idBackFile"];
  } else {
    keys = [...keys, "contractorNationality"];
  }
  if (laborContract.paymentMethod == PaymentMethodEnum.Transfer) {
    keys = [
      ...keys,
      "contractorBankCode",
      "contractorBankAccountNumber",
      "contractorBankAccountName",
      "passbookFile",
    ];
  }
  keys.map((key) => {
    if (!laborContract[key]) {
      canPublish = false;
    }
  });
  return canPublish;
};

interface Params {
  editLaborContract: LaborContract;
  setEditLaborContract: (a: any) => void;
  isGranted: boolean;
}

export default ({
  editLaborContract,
  setEditLaborContract,
  isGranted = false,
}: Params): any => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const callback = params.get("callback");

  const [open, setOpen] = useState(false);
  const [updateLaborContract] = useUpdateLaborContractMutation();
  const [updatePublicLaborContract] = useUpdatePublicLaborContractMutation();
  const [submitPublicLaborContract] = useSubmitPublicLaborContractMutation();
  const [setPublicLaborContractSignature] =
    useSetPublicLaborContractSignatureMutation();
  const calculatedAmount = newCalculateContractTotals(
    editLaborContract.payDate,
    editLaborContract.amount,
    editLaborContract!.contractorTaxClassification as TaxClassificationEnum,
    editLaborContract!.incomeType as IncomeTypeEnum,
    editLaborContract.contractorNoInsurance
  );

  const onSubmit = async (uuid, status) => {
    try {
      await submitPublicLaborContract({
        variables: {
          uuid,
          status,
        },
        refetchQueries: ["getPublicLaborContract"],
      });
    } catch (e: any) {
      NotificationPopup.error(`發生問題: ${e.message}`);
    }
  };
  const onUpdate = async (editLaborContract) => {
    try {
      if (isGranted) {
        if (editLaborContract.amount) {
          editLaborContract.amount = parseInt(editLaborContract.amount);
        }
        if (editLaborContract.startDate && editLaborContract.endDate) {
          if (editLaborContract.startDate > editLaborContract.endDate) {
            NotificationPopup.error(`勞務期間（迄）不得早於勞務期間（起）`);
            return;
          }
        }
        await updateLaborContract({
          variables: {
            laborContract: omit(editLaborContract, [
              "createdAt",
              "updatedAt",
              "id",
              "company",
              "idFrontFile",
              "signatureFile",
              "idBackFile",
              "passbookFile",
              "uuid",
            ]),
            id: editLaborContract.id,
          },
          refetchQueries: ["getLaborContract", "getLaborContracts"],
        });
      } else {
        const laborContractDto = pick(editLaborContract, [
          "contractorName",
          "contractorAddress",
          "contractorEmail",
          "contractorPhone",
          "contractorIdNumber",
          "contractorTaxClassification",
          "contractorContactAddress",
          "contractorNoInsurance",
          "contractorBankCode",
          "contractorBankAccountNumber",
          "contractorBankAccountName",
          "signatureFileId",
          "passbookFileId",
          "idBackFileId",
          "idFrontFileId",
          "contractorNationality",
        ]);

        await updatePublicLaborContract({
          variables: {
            publicLaborContract: laborContractDto,
            uuid: editLaborContract.uuid,
          },
          refetchQueries: ["getPublicLaborContract"],
        });
        if (callback && callback.includes("http")) {
          window.location.href = callback;
        }
      }
      NotificationPopup.success(`儲存成功`);
    } catch (e: any) {
      NotificationPopup.error(`發生問題: ${e.message}`);
      console.error(e);
    }
  };
  const onUpload = async (editLaborContract) => {
    try {
      await submitPublicLaborContract({
        variables: {
          uuid: editLaborContract.uuid,
          status: editLaborContract.status as string,
        },
        refetchQueries: ["getPublicLaborContract"],
      });
      NotificationPopup.success(`上傳成功`);
    } catch (e: any) {
      NotificationPopup.error(`發生問題: ${e.message}`);
      console.error(e);
    }
  };
  const onSign = async (signatureFileId, uuid) => {
    try {
      await setPublicLaborContractSignature({
        variables: {
          uuid,
          signatureFileId,
        },
        refetchQueries: ["getPublicLaborContract"],
      });
      NotificationPopup.success(`簽名完成`);
    } catch (e: any) {
      NotificationPopup.error(`發生問題: ${e.message}`);
      console.error(e);
    }
  };

  const onChange = (fieldName) => {
    return (value) => {
      setEditLaborContract({ ...editLaborContract, [fieldName]: value });
    };
  };

  const contractorFieldEditable = isGranted
    ? editLaborContract.status == LaborContractStatusEnum.Draft
    : editLaborContract.status == LaborContractStatusEnum.Unsigned &&
    editLaborContract.editable;
  const allFieldsEditable =
    isGranted && editLaborContract.status == LaborContractStatusEnum.Draft;

  // const inputProp = {InputProps: {readOnly: !allFieldsEditable}}
  // const dateInputProp = {InputProps: {startAdornment: <></>, readOnly: !allFieldsEditable}}
  // const contractorInputProp = {InputProps: {readOnly: !contractorFieldEditable}}
  const inputProp = { disabled: !allFieldsEditable };
  const dateInputProp = {
    InputProps: { startAdornment: <></> },
    disabled: !allFieldsEditable,
  };
  const contractorRequired =
    (editLaborContract.status == LaborContractStatusEnum.Draft &&
      !editLaborContract?.editable) ||
    (editLaborContract.status == LaborContractStatusEnum.Unsigned &&
      editLaborContract.editable);
  const contractorInputProp = {
    disabled: !contractorFieldEditable,
    required: contractorRequired,
  };

  const isCitizen =
    editLaborContract?.contractorTaxClassification ==
    TaxClassificationEnum.Citizen;
  const idTitle = isCitizen ? "身分證" : "居留證/護照";
  const isSigned = !!editLaborContract.signatureFile;

  return (
    <Card variant="outlined">
      <Grid
        container
        justifyContent={"center"}
        style={{ ...titleBarStyle, fontSize: 24, padding: 10 }}
      >
        {editLaborContract?.company?.name}
        <div style={{ marginRight: 50 }}></div>
        勞務報酬單
      </Grid>
      <div style={{ padding: 30 }}>
        {isGranted && (
          <Grid container style={{ marginBottom: 5 }}>
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"flex-end"}
              style={{ marginTop: 10 }}
            >
              <Grid item xs={5} sm={3}>
                <EditTextField
                  required
                  {...inputProp}
                  setter={onChange("operator")}
                  value={editLaborContract?.operator}
                  label={"經辦人"}
                >
                </EditTextField>
              </Grid>
              <Grid item xs={5} sm={3} style={{ marginLeft: 10 }}>
                <EditTextField
                  required
                  {...inputProp}
                  setter={onChange("operatorPhone")}
                  value={editLaborContract?.operatorPhone}
                  label={"聯絡電話"}
                />
              </Grid>
            </Grid>
            <Grid container sm={6} md={3} alignItems={"center"}>
              <Select
                disabled={!allFieldsEditable}
                defaultValue={0}
                onChange={({ target }) => {
                  const contractor: any =
                    editLaborContract?.company?.contractors.find(
                      (contractor) => {
                        return contractor.id == target.value;
                      }
                    );
                  if (contractor) {
                    console.log(contractor);
                    setEditLaborContract({
                      ...editLaborContract,
                      contractorName: contractor.name,
                      contractorPhone: contractor.phone,
                      contractorEmail: contractor.email,
                      contractorAddress: contractor.address,
                      contractorIdNumber: contractor.idNumber,
                      contractorTaxClassification: contractor.taxClassification,
                      contractorNoInsurance: contractor.noInsurance,
                      contractorNationality: contractor.nationality,
                      contractorContactAddress: contractor.contactAddress,
                      contractorBankCode: contractor.bankCode,
                      contractorBankAccountNumber: contractor.bankAccountNumber,
                      contractorBankAccountName: contractor.bankAccountName,
                    });
                  }
                }}
              >
                <MenuItem value={0}>選擇所得人或自行輸入</MenuItem>
                {editLaborContract?.company?.contractors.map((contractor) => {
                  return (
                    <MenuItem value={contractor.id}>{contractor.name}</MenuItem>
                  );
                })}
              </Select>
            </Grid>

            <Grid container item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editLaborContract?.editable}
                    onChange={({ target }) => {
                      setEditLaborContract({
                        ...editLaborContract,
                        editable: target.checked,
                      });
                    }}
                    disabled={!allFieldsEditable}
                  />
                }
                label="所得人可自行填寫基本資料"
              />
            </Grid>
            <Grid
              container
              item
              sm={12}
              md={5}
              alignItems={"center"}
              justifyContent={"flex-end"}
              style={{ marginTop: 10 }}
            >
              <EditTextField
                required
                {...dateInputProp}
                setter={onChange("date")}
                value={
                  editLaborContract?.date
                    ? format(new Date(editLaborContract?.date), "yyyy-MM-dd")
                    : null
                }
                type={"date"}
                style={{}}
                label={"填表日期"}
              />
              <EditTextField
                required
                {...dateInputProp}
                setter={onChange("payDate")}
                value={
                  editLaborContract?.payDate
                    ? format(new Date(editLaborContract?.payDate), "yyyy-MM-dd")
                    : null
                }
                type={"date"}
                style={{ marginLeft: 10 }}
                label={"支付日期"}
                InputProps={{
                  startAdornment: (
                    <DescriptionButton title={"支付日期"}>
                      支付日期應以實際匯款之「單日給付金額之日期」填寫。
                      <br />
                      將單日給付報酬拆分多筆勞務報酬單須負擔「未依規定扣繳之罰則」。
                    </DescriptionButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        {!isGranted && (
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            經辦人：{editLaborContract?.operator} &nbsp; 聯絡電話：
            {editLaborContract?.operatorPhone}
          </Grid>
        )}

        <Grid
          container
          direction={"column"}
          style={{
            borderLeft: "1px solid",
            borderTop: "1px solid",
            borderColor: theme.palette.primary.main,
          }}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              ...styleBorderBR,
              ...titleBarStyle,
              width: "100%",
              padding: 10,
            }}
          >
            所得人
          </Grid>
          <Grid container>
            <Grid
              container
              item
              direction={"column"}
              xs={12}
              sm={6}
              style={{ ...styleBorderBR, padding: 15 }}
            >
              <Grid item container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorName")}
                  value={editLaborContract?.contractorName || ""}
                  label={"姓名"}
                />
              </Grid>
              <Grid item container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorPhone")}
                  value={editLaborContract?.contractorPhone || ""}
                  label={"電話"}
                />
              </Grid>
              <Grid item container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorEmail")}
                  value={editLaborContract?.contractorEmail}
                  label={"Email"}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction={"column"}
              style={{ ...styleBorderBR, padding: 15, paddingTop: 25 }}
              xs={12}
              sm={6}
            >
              <Grid container alignItems={"center"}>
                <EditSelect
                  {...contractorInputProp}
                  setter={onChange("contractorTaxClassification")}
                  style={{}}
                  value={editLaborContract?.contractorTaxClassification}
                  label={"國籍"}
                >
                  <MenuItem value={TaxClassificationEnum.Citizen}>
                    {TaxClassificationMap.citizen}
                  </MenuItem>
                  <MenuItem value={TaxClassificationEnum.ResidentAlien}>
                    {TaxClassificationMap.residentAlien}
                  </MenuItem>
                  <MenuItem value={TaxClassificationEnum.NonResidentAlien}>
                    {TaxClassificationMap.nonResidentAlien}
                  </MenuItem>
                </EditSelect>
                {editLaborContract?.contractorTaxClassification !=
                  TaxClassificationEnum.Citizen && (
                    <EditTextField
                      {...contractorInputProp}
                      style={{ marginLeft: 10 }}
                      setter={onChange("contractorNationality")}
                      value={editLaborContract?.contractorNationality}
                      label="國家"
                    />
                  )}
              </Grid>
              <Grid container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorIdNumber")}
                  value={editLaborContract?.contractorIdNumber}
                  label={isCitizen ? "身分證字號" : "居留證號/護照號碼"}
                />
              </Grid>
              {console.log(
                `editLaborContract.contractorNoInsurance: ${editLaborContract.contractorNoInsurance}`
              )}
              <Grid container alignItems={"center"} style={{ paddingTop: 10 }}>
                <EditSelect
                  {...contractorInputProp}
                  value={
                    editLaborContract.contractorNoInsurance !== null
                      ? editLaborContract.contractorNoInsurance
                        ? 1
                        : 0
                      : null
                  }
                  setter={(val) => {
                    onChange("contractorNoInsurance")(!!val);
                  }}
                  label={
                    editLaborContract.contractorNoInsurance !== null
                      ? "免扣二代健保"
                      : "請確認二代健保狀態"
                  }
                  disabled={!contractorFieldEditable}
                  startAdornment={
                    <DescriptionButton title={"免扣二代健保"}>
                      若選擇「是」，免扣二代健保，需出具在職業工會或投保單位投保健保證明，否則可能需負擔「未依規定扣繳之罰則」。
                    </DescriptionButton>
                  }
                >
                  <MenuItem value={1}>{NoInsuranceMap.yes}</MenuItem>
                  <MenuItem value={0}>{NoInsuranceMap.no}</MenuItem>
                </EditSelect>
              </Grid>
            </Grid>
            <Grid container style={{ padding: 10, ...styleBorderBR }}>
              <Grid container item alignItems={"center"} xs={12} sm={5}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorAddress")}
                  value={editLaborContract?.contractorAddress}
                  label={"戶籍地址"}
                />
              </Grid>
              <Grid
                container
                item
                justifyContent={"center"}
                alignItems={"center"}
                xs={12}
                sm={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!contractorFieldEditable}
                      onChange={({ target }) => {
                        setEditLaborContract({
                          ...editLaborContract,
                          contractorContactAddress: target.checked
                            ? editLaborContract.contractorAddress
                            : "",
                        });
                      }}
                    />
                  }
                  label="同戶籍地址"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorContactAddress")}
                  value={editLaborContract?.contractorContactAddress}
                  label={"通訊地址"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={"center"}
            alignItems={"center"}
            style={{ ...styleBorderBR, ...titleBarStyle, padding: 10 }}
          >
            {`${idTitle}上傳`}
          </Grid>

          <Grid container direction={"row"} style={{ minHeight: 100 }}>
            <Grid
              container
              item
              direction={"column"}
              xs={12}
              sm={isCitizen ? 6 : 12}
              style={{ ...styleBorderBR, padding: 10, alignItems: "center" }}
            >
              <PreviewImage file={editLaborContract?.idFrontFile} />
              {contractorFieldEditable && (
                <UploadFile
                  uploadTitle={
                    `上傳${idTitle}正面` + (contractorRequired ? "*" : "")
                  }
                  onSuccess={async (res) => {
                    if (res && res.length > 0) {
                      await onUpdate({
                        ...editLaborContract,
                        idFrontFileId: res[0].id,
                      });
                    }
                  }}
                />
              )}
            </Grid>
            {isCitizen && (
              <Grid
                container
                item
                direction={"column"}
                xs={12}
                sm={6}
                style={{ ...styleBorderBR, padding: 10, alignItems: "center" }}
              >
                <PreviewImage file={editLaborContract.idBackFile} />
                {contractorFieldEditable && (
                  <UploadFile
                    uploadTitle={
                      `上傳${idTitle}反面` + (contractorRequired ? "*" : "")
                    }
                    onSuccess={async (res) => {
                      if (res && res.length > 0) {
                        await onUpdate({
                          ...editLaborContract,
                          idBackFileId: res[0].id,
                        });
                      }
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
          <Grid container direction={"column"}>
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                ...styleBorderBR,
                ...titleBarStyle,
                width: "100%",
                padding: 10,
              }}
            >
              勞務內容
              <DescriptionButton>
                <Typography>
                  此欄位由公司方可異動，若對此欄位有疑義請聯繫經辦人
                </Typography>
              </DescriptionButton>
            </Grid>
            <Grid
              container
              direction={"column"}
              alignItems={"flex-start"}
              style={{ ...styleBorderBR, width: "100%" }}
            >
              <Grid container alignItems={"center"} style={{ padding: 10 }}>
                <EditTextField
                  required
                  {...inputProp}
                  setter={onChange("contractContent")}
                  value={editLaborContract?.contractContent}
                  label={"勞務內容"}
                />
                <EditTextField
                  required
                  {...dateInputProp}
                  setter={onChange("startDate")}
                  value={
                    editLaborContract?.startDate
                      ? format(
                        new Date(editLaborContract?.startDate),
                        "yyyy-MM-dd"
                      )
                      : null
                  }
                  type={"date"}
                  style={{}}
                  label={"勞務期間(起)"}
                />
                <EditTextField
                  required
                  {...dateInputProp}
                  setter={onChange("endDate")}
                  value={
                    editLaborContract?.endDate
                      ? format(
                        new Date(editLaborContract?.endDate),
                        "yyyy-MM-dd"
                      )
                      : null
                  }
                  type={"date"}
                  style={{ marginLeft: 10, marginRight: 20 }}
                  label={"勞務期間(迄)"}
                />

                <Grid
                  container
                  alignItems={"center"}
                  style={{ paddingTop: 10 }}
                >
                  <EditSelect
                    required
                    setter={onChange("incomeType")}
                    value={editLaborContract?.incomeType}
                    label={"所得類別"}
                    disabled={!allFieldsEditable}
                    startAdornment={
                      <DescriptionButton title={"所得類別"}>
                        <DataGridPro
                          style={{ minHeight: 300 }}
                          columns={[
                            {
                              field: "col1",
                              headerName: "所得類別",
                              width: 150,
                            },
                            { field: "col2", headerName: "說明", width: 450 },
                          ]}
                          rows={incomeDescriptionRows}
                          hideFooter={true}
                        />
                      </DescriptionButton>
                    }
                  >
                    <MenuItem value={IncomeTypeEnum.A_9b}>
                      {IncomeTypeMap.a_9b}
                    </MenuItem>
                    <MenuItem value={IncomeTypeEnum.A_9a}>
                      {IncomeTypeMap.a_9a}
                    </MenuItem>
                    <MenuItem value={IncomeTypeEnum.A_50}>
                      {IncomeTypeMap.a_50}
                    </MenuItem>
                    <MenuItem value={IncomeTypeEnum.A_92}>
                      {IncomeTypeMap.a_92}
                    </MenuItem>
                  </EditSelect>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"}>
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              xs={12}
              style={{
                ...styleBorderBR,
                ...titleBarStyle,
                width: "100%",
                padding: 10,
              }}
            >
              領款金額
              <DescriptionButton>
                <Typography>
                  此欄位由公司方可異動，若對此欄位有疑義請聯繫經辦人
                </Typography>
              </DescriptionButton>
            </Grid>

            <Grid
              container
              direction={"column"}
              alignItems={"flex-start"}
              xs={12}
              sm={6}
              style={{ ...styleBorderBR, padding: 10 }}
            >
              <Grid container alignItems={"center"}>
                <EditTextField
                  required
                  {...inputProp}
                  setter={onChange("amount")}
                  defaultValue={0}
                  value={editLaborContract?.amount}
                  label={"支領金額"}
                  InputLabelProps={{
                    shrink: editLaborContract?.amount ?? false,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
              </Grid>
              <Grid container alignItems={"center"}>
                <StyleTextField
                  {...inputProp}
                  value={calculatedAmount.taxAmount?.toLocaleString()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <DescriptionButton>
                        <Typography>
                          {taxDescription(
                            editLaborContract!
                              .contractorTaxClassification as TaxClassificationEnum,
                            editLaborContract.incomeType as IncomeTypeEnum
                          )}
                        </Typography>
                      </DescriptionButton>
                    ),
                  }}
                  label={"代扣所得稅"}
                />
              </Grid>
              <Grid container alignItems={"center"}>
                <StyleTextField
                  {...inputProp}
                  value={calculatedAmount.insuranceAmount?.toLocaleString()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <DescriptionButton>
                        <Typography>
                          {insuranceDescription(
                            editLaborContract.contractorTaxClassification as TaxClassificationEnum,
                            editLaborContract.incomeType as IncomeTypeEnum
                          )}
                        </Typography>
                      </DescriptionButton>
                    ),
                  }}
                  label={"二代健保"}
                />
              </Grid>
              <Grid container alignItems={"center"}>
                <StyleTextField
                  {...inputProp}
                  defaultValue={0}
                  value={
                    (editLaborContract.amount || 0) -
                    calculatedAmount.taxAmount -
                    calculatedAmount.insuranceAmount
                  }
                  label={"支領淨額"}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <DescriptionButton>
                        <Typography>
                          支領淨額為扣除代扣所得稅及二代健保後之實領金額，若所得人之國籍及是否免扣二代健保未填
                          ，則非最終之實領金額。
                        </Typography>
                      </DescriptionButton>
                    ),

                    inputComponent: NumberFormatCustom as any,
                  }}
                />
              </Grid>
              <Grid container alignItems={"center"} style={{ marginTop: 10 }}>
                <EditSelect
                  required
                  setter={onChange("paymentMethod")}
                  style={{}}
                  value={editLaborContract?.paymentMethod}
                  label={"付款方式"}
                  disabled={!allFieldsEditable}
                >
                  {map(PaymentMethodMap, (val, key) => (
                    <MenuItem key={key} value={key}>
                      {val}
                    </MenuItem>
                  ))}
                </EditSelect>
              </Grid>
            </Grid>
            <Grid
              container
              direction={"column"}
              style={{ ...styleBorderBR, padding: 10 }}
              xs={12}
              sm={6}
            >
              <Grid container direction={"column"} alignItems={"center"}>
                <Dialog open={open} fullWidth={true}>
                  <DialogContent>
                    <Signature
                      contractId={editLaborContract.id}
                      onSuccess={(res) => {
                        setOpen(false);
                        if (res && res.length > 0) {
                          onSign(res[0].id, editLaborContract.uuid);
                        }
                      }}
                      onCancel={() => {
                        setOpen(false);
                      }}
                    />
                  </DialogContent>
                </Dialog>

                <PreviewImage
                  isSignature={true}
                  file={editLaborContract.signatureFile}
                />
                <div>
                  {!isGranted &&
                    editLaborContract.status ==
                    LaborContractStatusEnum.Unsigned && (
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => setOpen(true)}
                      >
                        領款人簽名*
                      </Button>
                    )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"}>
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              style={{ ...styleBorderBR, ...titleBarStyle, padding: 10 }}
            >
              匯款帳號
            </Grid>
            <Grid
              container
              direction={"column"}
              style={{ ...styleBorderBR, padding: 15 }}
              xs={12}
              sm={6}
            >
              <Grid item container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorBankCode")}
                  value={editLaborContract?.contractorBankCode}
                  label={"銀行代碼"}
                />
              </Grid>
              <Grid item container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorBankAccountNumber")}
                  value={editLaborContract?.contractorBankAccountNumber}
                  label={"匯款帳號"}
                />
              </Grid>
              <Grid item container alignItems={"center"}>
                <EditTextField
                  {...contractorInputProp}
                  setter={onChange("contractorBankAccountName")}
                  value={editLaborContract?.contractorBankAccountName}
                  label={"帳戶戶名"}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction={"column"}
              alignItems={"flex-start"}
              style={{ ...styleBorderBR, padding: 10 }}
              xs={12}
              sm={6}
            >
              <Grid
                container
                item
                direction={"column"}
                style={{ padding: 10, alignItems: "center" }}
              >
                <PreviewImage file={editLaborContract.passbookFile} />
                {contractorFieldEditable && (
                  <UploadFile
                    uploadTitle={
                      "上傳存摺封面" + (contractorRequired ? "*" : "")
                    }
                    onSuccess={(res) => {
                      if (res && res.length > 0) {
                        onUpdate({
                          ...editLaborContract,
                          passbookFileId: res[0].id,
                        });
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"center"}
          spacing={2}
          style={{ marginTop: 10 }}
        >
          {((isGranted &&
            editLaborContract.status == LaborContractStatusEnum.Draft) ||
            (!isGranted &&
              editLaborContract.status ==
              LaborContractStatusEnum.Unsigned)) && (
              <Grid item>
                <ConfirmButton
                  message={
                    isGranted
                      ? "僅是儲存，尚未送出給廠商，若已確認資訊無誤要送出給廠商簽回，請務必點選「發佈」，產生公開連結/送出給廠商。"
                      : "僅是儲存，尚未回傳公司，若已確認資訊無誤要回傳給公司，請務必點選「發佈」。"
                  }
                  onConfirm={() => {
                    onUpdate(editLaborContract);
                  }}
                >
                  儲存
                </ConfirmButton>
              </Grid>
            )}
          {editLaborContract.status == LaborContractStatusEnum.Unsigned && (
            <Grid item>
              {!isSigned || !checkPublish(editLaborContract) ? (
                <AlertDialogButton
                  message={"請確認所有＊欄位都已填寫，才可發佈。"}
                >
                  發佈
                </AlertDialogButton>
              ) : (
                <ConfirmButton
                  message={"確定要將勞報單回傳給公司了嗎？"}
                  onConfirm={async () => {
                    if (editLaborContract.editable) {
                      await onUpdate(editLaborContract);
                    }
                    await onSubmit(editLaborContract.uuid, "signed");
                  }}
                  buttonProps={{ color: "primary" }}
                >
                  發佈
                </ConfirmButton>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </Card>
  );
};
