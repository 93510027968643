import Page from "../common/Page";
import {AppBar, Box, Button, Grid, Tab, Tabs} from "@mui/material";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import {DataGridPro, GridColDef, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {checkAlert, DataGridLocale, DateValueGetter, DefaultValueGetter, useTabIndex} from "../common/Utils";
import React, {useCallback, useContext, useState} from "react";
import TabPanel from "../component/TabPanel";
import {
    Authorization,
    SalaryItemTypeEnum,
    useCreateSalaryExtendMutation,
    useGetSalariesQuery,
    useGetSalaryExtendsQuery,
    useGetSalaryGroupsQuery, useRemoveSalaryExtendMutation, useRemoveSalaryGroupMutation,
    useUpdateSalaryExtendMutation
} from "../generated/graphql";
import {CompanyContext} from "../routes";
import {filterByCompany} from "../leave/LeavePage";
import {find, omit, reduce} from "lodash";
import SalaryButton from "./SalaryButton";
import {InputType} from "../common/FormRenderer";
import {getAccessToken, useEmployeeList} from "../auth/Auth";
import UpdatePopupButton from "../component/UpdatePopupButton";
import NotificationPopup from "../common/NotificationPopup";
import CreateSalary from "./CreateSalary";
import {SalaryItemTypeMap} from "../common/Constant";
import DownloadButton from "../component/DownloadButton";
import UploadFile from "../common/UploadFile";
import { saveAs } from "file-saver";
import SendSalaryPopup from "./SendSalaryPopup";
import ConfirmButton from "../component/ConfirmButton";
import {useUser} from "../auth/Auth";
import SalaryNewSysDialog from "../component/SalaryNewSysDialog";

const salaryColumns: GridColDef[] = [
    {field: "id", headerName: "ID", width: 80, hide: true},
    {field: "employee.code", headerName: "員工編號", width: 120, valueGetter: DefaultValueGetter},
    {field: "employee.name", headerName: "姓名", width: 120, valueGetter: DefaultValueGetter},
    {field: "salaryGroup.title", headerName: "薪資發放名稱", width: 120, valueGetter: DefaultValueGetter},
    {field: "salaryGroup.yearMonth", headerName: "薪資年月", width: 120, valueGetter: DefaultValueGetter},
    {
        field: "salaryItems.plus", headerName: "應發金額", width: 120, valueGetter: (params: GridValueGetterParams) =>
        {
            return reduce(params?.row.salaryItems, (acc, item) => acc + ([SalaryItemTypeEnum.Normal, SalaryItemTypeEnum.Plus].includes(item.type) ? item.amount : 0), 0)
        }
    },
    {
        field: "salaryItems.minus", headerName: "應減金額", width: 120, valueGetter: (params: GridValueGetterParams) =>
        {
            return reduce(params?.row.salaryItems, (acc, item) => acc + (item.type == SalaryItemTypeEnum.Minus ? item.amount : 0), 0)
        }
    },
    {
        field: "salaryItems.total", headerName: "實發金額", width: 120, valueGetter: (params: GridValueGetterParams) =>
        {
            return reduce(params?.row.salaryItems, (acc, item) =>
            {
                const multiply = [SalaryItemTypeEnum.Normal, SalaryItemTypeEnum.Plus].includes(item.type) ? 1 :
                    (item.type == SalaryItemTypeEnum.Minus) ? -1 : 0;
                return acc + item.amount * multiply;
            }, 0)
        }
    },
    {
        field: "action", headerName: "動作", width: 150,
        renderCell: (params) =>
        {
            return <SalaryButton salary={params.row}/>
        }
    }
]

export default () =>
{
    const user = useUser();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);
    const [tabIndex, setTabIndex] = useTabIndex();
    const {loading: salaryGroupsLoading, data: salaryGroupsData, error: salaryGroupsError} = useGetSalaryGroupsQuery();
    const {loading: salariesLoading, data: salariesData, error: salariesError} = useGetSalariesQuery();
    const {
        loading: salaryExtendsLoading,
        data: salaryExtendsData,
        error: salaryExtendsError
    } = useGetSalaryExtendsQuery();
    const [updateSalaryExtend] = useUpdateSalaryExtendMutation();
    const [createSalaryExtend] = useCreateSalaryExtendMutation();
    const [removeSalaryExtend] = useRemoveSalaryExtendMutation();
    const [removeSalaryGroup] = useRemoveSalaryGroupMutation();
    const company = useContext(CompanyContext);
    checkAlert(company, Authorization.Personnel);
    const employees = useEmployeeList();
    const onExport = useCallback(
        async (object, {resetForm}, setOpen) => {
        const url = `${process.env.REACT_APP_BACKEND_URI}/salary/export?companyId=${company?.id}&type=salary&salaryGroupId=${object.id}`
        const res = await fetch(
            url,
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        );
        const blob = await res.blob();
        saveAs(blob, `薪資發放.xlsx`);
    }, [company]);
    const onCreateSalaryExtend = useCallback(async (salaryExtendDto, {resetForm}, setOpen) =>
    {
        try {
            await createSalaryExtend({
                variables: {salaryExtendDto: salaryExtendDto},
                refetchQueries: ["getSalaryExtends"],
            });
            NotificationPopup.success(`新增完成`);
            resetForm();
            setOpen(false);
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }, [createSalaryExtend]);
    const onUpdateSalaryExtend = useCallback(async (salaryExtendDto, {resetForm}, setOpen) =>
    {
        try {
            await updateSalaryExtend({
                variables: {salaryExtendDto: omit(salaryExtendDto, ["id"]), id: salaryExtendDto.id},
                refetchQueries: ["getSalaryExtends"],
            });
            NotificationPopup.success(`修改完成`);
            resetForm();
            setOpen(false);
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }, [updateSalaryExtend])

    const onRemove = useCallback(async (id) =>
    {
        try {
            await removeSalaryExtend({
                variables: {id},
                refetchQueries: ["getSalaryExtends"],
            });
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }, []);
    const onRemoveSalaryGroup = useCallback(async (id, user) =>
    {
        try {
            await removeSalaryGroup({
                variables: {id: id, userId: user.id},
                refetchQueries: ["getSalaryGroups", "getSalaries"],
            });
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }, []);
    const salaryGroupColumns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 80, hide: true},
        {field: "yearMonth", headerName: "薪資年月", width: 120},
        {field: "title", headerName: "薪資發放名稱", width: 160},
        {field: "startDate", headerName: "起始日", width: 120, valueGetter: DateValueGetter},
        {field: "endDate", headerName: "結束日", width: 120, valueGetter: DateValueGetter},
        {
            field: "salaries", headerName: "人數", width: 120,
            valueGetter: params => (params as any).value.length
        },
        {
            field: "status", headerName: "狀態",
            valueGetter: params => new Date() > new Date(params.row.payDate) ? "已發放" : "未發放"
        },
        {field: "payDate", headerName: "發放日期", valueGetter: DateValueGetter},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) =>
            {
                return <>
                    <ConfirmButton onConfirm={async () => {
                        await onRemoveSalaryGroup(params.row.id, user)
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ]
    const salaryExtendMutationColumns = (params?: any) =>
    {
        return [
            {
                name: "id",
                label: "ID",
                value: params?.row.id,
                type: InputType.hidden,
            },
            {
                name: "employeeId",
                label: "員工",
                type: InputType.autoComplete,
                options: filterByCompany(employees, company).map((employee) => ({
                    value: employee.id,
                    label: `${employee.code} ${employee.name}`
                })),
                value: params?.row.employee.id,
            },
            {
                name: "yearMonth",
                label: "薪資年月",
                type: "month",
                value: params?.row.yearMonth && params?.row.yearMonth.includes('/') ? params?.row.yearMonth.split('/').join('-') : params?.row.yearMonth
            },
            {name: "subject", label: "薪資科目", value: params?.row.subject},
            {name: "amount", label: "金額", value: params?.row.amount, type: "number"},
            {name: "description", label: "備註", value: params?.row.description},
            {
                name: "type",
                label: "加減項",
                value: params?.row.type,
                type: InputType.select,
                options: [{value: SalaryItemTypeEnum.Plus, label: "加項"}, {value: SalaryItemTypeEnum.Minus, label: "減項"}]
            }
        ]
    }
    const salaryExtendColumns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 80, hide: true},
        {field: "employee.code", headerName: "員工編號", width: 120, valueGetter: DefaultValueGetter},
        {field: "employee.name", headerName: "姓名", width: 120, valueGetter: DefaultValueGetter},
        {field: "yearMonth", headerName: "薪資年月", width: 120},
        {field: "subject", headerName: "薪資科目", width: 120},
        {field: "amount", headerName: "金額", width: 120},
        {field: "type", headerName: "加減項", width: 120, valueGetter: params => SalaryItemTypeMap[params.value]},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) =>
            {
                return <>
                    <UpdatePopupButton
                        title={"修改"}
                        columns={salaryExtendMutationColumns(params)}
                        onSubmit={onUpdateSalaryExtend}
                        submitTitle={"儲存"}
                    />&nbsp;
                    <ConfirmButton onConfirm={async () => {
                        await onRemove(params.row.id)
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ]
    return (
        <Page title={"salary page"}>

            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{background: "white"}}
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) =>
                    {
                        setTabIndex(newValue);
                        setSortModel([{field: "id", sort: "desc"}]);
                    }}
                >
                    <Tab label="薪資計算"/>
                    <Tab label="薪資單"/>
                    <Tab label="薪資科別加減項"/>
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <DefaultQueryHandler error={salaryGroupsError} loading={salaryGroupsLoading}>
                        <Box
                            width={"100%"}
                            style={{height: "100vh"}}
                        >

                            <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                                <Grid item>
                                    <CreateSalary/>
                                </Grid>
                                <Grid item>

                                    <UpdatePopupButton
                                        title={"匯出薪資明細"}
                                        columns={[{
                                                name: "id",
                                                label: "薪資發放名稱",
                                                type: InputType.select,
                                                options: filterByCompany(salaryGroupsData?.salaryGroups, company).map(
                                                    (salaryGroup)=>({value: salaryGroup.id, label: salaryGroup.title}))
                                            }]}
                                        onSubmit={onExport}
                                        submitTitle={"匯出"}
                                    />
                                </Grid>
                                <Grid item>
                                    <SendSalaryPopup salaryGroups={salaryGroupsData?.salaryGroups} />
                                </Grid>
                            </Grid>
                            <DataGridPro
                                style={{marginTop: 20}}
                                localeText={DataGridLocale}
                                autoHeight={true}
                                loading={salaryGroupsLoading}
                                rows={filterByCompany(salaryGroupsData?.salaryGroups, company) || []}
                                columns={salaryGroupColumns}
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) =>
                                {
                                    setSortModel(sortModel)
                                }}
                            />
                        </Box>
                    </DefaultQueryHandler>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <DefaultQueryHandler error={salariesError} loading={salariesLoading}>
                        <Box
                            width={"100%"}
                            style={{height: "100vh"}}
                        >
                            <DataGridPro
                                localeText={DataGridLocale}
                                autoHeight={true}
                                loading={salariesLoading}
                                rows={filterByCompany(salariesData?.salaries, company) || []}
                                columns={salaryColumns}
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) =>
                                {
                                    setSortModel(sortModel)
                                }}
                            />
                        </Box>
                    </DefaultQueryHandler>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <DefaultQueryHandler error={salaryExtendsError} loading={salaryExtendsLoading}>
                        <Box
                            width={"100%"}
                            style={{height: "100vh"}}
                        >
                            <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                                <Grid item>
                                    <UploadFile
                                        uploadTitle={"匯入薪資加減項"}
                                        uploadPath={`/salary/upload?type=salaryExtend&companyId=${company?.id}`}
                                        onSuccess={(res) =>
                                        {
                                            NotificationPopup.success(`匯入成功`);
                                            window.location.reload();
                                        }}></UploadFile>
                                </Grid>
                                <Grid item>
                                    <UpdatePopupButton
                                        title={"新增加減項"}
                                        columns={salaryExtendMutationColumns()}
                                        onSubmit={onCreateSalaryExtend}
                                        submitTitle={"新增"}
                                    />
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        title={"匯出薪資加減項"}
                                        path={`/salary/export?type=salaryExtend&companyId=${company?.id}`}
                                        filename={"薪資加減項列表"}
                                        companyId={company?.id}/>
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        color={"secondary"}
                                        title={"薪資加減項匯入範例"}
                                        path={`/salary/export?type=salaryExtend&companyId=${company?.id}&sample=1`}
                                        filename={"薪資加減項匯入範例"}
                                        companyId={company?.id}/>
                                </Grid>
                            </Grid>
                            <DataGridPro
                                style={{marginTop: 20}}
                                localeText={DataGridLocale}
                                autoHeight={true}
                                loading={salaryExtendsLoading}
                                rows={filterByCompany(salaryExtendsData?.salaryExtends, company) || []}
                                columns={salaryExtendColumns}
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) =>
                                {
                                    setSortModel(sortModel)
                                }}
                            />
                        </Box>
                    </DefaultQueryHandler>
                </TabPanel>
            </AppBar>
            <SalaryNewSysDialog url={""}/>
        </Page>
    );
}