import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../theme/Theme";

export default ({ url }: { url: string }) => {
  // 使用 useState 管理对话框的开关状态
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // 关闭对话框
    setOpen(false);
  };

  const handleRedirect = () => {
    // 处理跳转逻辑
    window.location.replace(`http://localhost:5173/${url}`);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle style={{ backgroundColor: theme.palette.primary.dark }}>
        <Typography variant="h3" align="center">
          薪資小工具新系統啟用公告
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{margin: "30px auto"}}>
          親愛的用戶，您好！<br /><br />
          我們很高興地通知您，薪資小工具新系統已正式啟用！從 2025年1月起，所有薪資計算需在新系統中完成，並請將 2025年1月1日以後的加班/兼職、假勤、薪資加減項等資料統一於新系統中輸入。<br /><br />
          新系統登入方式<br /><br />
          1.前往新系統登入頁面：<br /><br />
          <a href="https://cloud-new.orange3.com.tw/login">https://cloud-new.orange3.com.tw/login</a><br /><br />
          2.點擊「重設密碼」。<br /><br />
          3.輸入您的註冊電子郵件地址，並依照郵件指示重設密碼。<br /><br />
          4.完成密碼重設後，即可登入新系統！<br /><br />
          <br /><br />
          舊系統關閉時間<br /><br />
          關閉日期：2025年2月28日<br /><br />
          舊系統僅支援至 2024年12月 的薪資計算。<br /><br />
          重要提醒：<br /><br />
          過往薪資計算結果 無法自動轉移至新系統，請務必於 2025年2月28日前，下載並保存所有舊系統中的薪資紀錄。<br /><br />
          <br /><br />
          如對於本次公告有任何疑問，請聯繫直誠官方LINE客服 (ID： @932coslc)。<br /><br />
          非常感謝您的理解與支持！<br /><br />
          直誠薪資小工具團隊 敬上<br /><br />
        </div>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            // handleRedirect();
            handleClose();
          }}
        >
          確認
        </Button>
      </DialogContent>
    </Dialog>
  );
};
